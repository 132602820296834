import React from 'react';

import founder from '../assets/pictures/founder.png';
import founder1 from '../assets/pictures/cofounder_y.png';
import founder2 from '../assets/pictures/cofounder_h.png';
import founder3 from '../assets/pictures/cofounder_k.png';



export const Team = () => {
    return (
        <div className="container-fluid team pt-5 py-5 justify-content-center">
            <div className="row justify-content-center">
                <div className="col-md-9 wh_8 text-center">
                    Meet the Team
                </div>
                <div className="col-md-9 th1 text-center pt-2 py-5">
                    Hee-Haw
                </div>
            </div>
            <div className="row justify-content-center gap-5 pt-5 py-5 mb-5">
                <div className="col-12  circle10  position-relative">
                   <img className="img founder rounded-circle " src={founder} alt="founder"/>
                        {/* <div className="p1 f_title position-relative">ShapeShifter
                            <div className="f_title1">Founder SDE</div>
                        </div> */}
                        
                </div> 
                <div className="col-12   circle11 position-relative">
                   <img className="img founder rounded-circle" src={founder1} alt="founder"/>
                        {/* <div className="p1 f_title position-relative">ShapeShifter <div className=" f_title1">Founder SDE</div></div> */}
                        
                </div> 
            </div>
            <div className="row justify-content-center gap-5 py-5 mt-5">
                <div className="col-12   circle12 position-relative">
                   <img className="img founder rounded-circle" src={founder2} alt="founder"/>
                        {/* <div className="p1 f_title">ShapeShifter <div className="p1 f_title1">Founder SDE</div></div> */}
                        
                </div> 
                <div className="col-12  circle13  position-relative">
                   <img className="img founder rounded-circle" src={founder3} alt="founder"/>
                        {/* <div className="p1 f_title">ShapeShifter <div className="p1 f_title1">Founder SDE</div></div> */}
                        
                </div> 
            </div>
           
        </div>
        
        
    );
}






