import React from 'react';
import { Button, Form, Label, Input, FormText,Link } from 'reactstrap';
import flag from '../assets/pictures/usa_s.png';

export const Footer = () => {
  return (
    <footer className="footer-container text-lg-start container-fluid">
        <div className="container pb-1">
            <div className="row pt-5 mx-5 px-md-5 justify-content">
               
                <div className="col-md-4 col-6">
                    <p className="h6 ">COMMUNITY</p>
                    <ul className="d-inline-block">
                        <li className=""><a className="" href="#">DAO</a></li>
                        <li><a href="#">Events</a></li>
                        {/* <li><a href="#">FAQ</a></li> */}
                        <li><a href="#">Publicity</a></li>
                    </ul>
                </div>
               
                <div className="col-md-4 col-6">
                    <p className="h6">PRODUCTS</p>
                    <ul className="d-inline-block">
                        <li><a href="#">PAD NFT</a></li>
                        <li><a href="#">MetaParallel Metaverse</a></li>
                        <li><a href="#">Use Cases</a></li>
                    </ul>
                    
                    
                </div>
                <div className="col-md-4 col-6">
                    <p className="h6 ml-5">ABOUT US</p>
                    <ul className="d-inline-block">
                        <li className=""><a href="#">Our Mission</a></li>
                        {/* <li><a href="#">Our Impact</a></li> */}
                        {/* <li><a href="#">PAD History</a></li> */}
                        <li className="d-flex"><a href="#">Careers(hiring!)</a></li>
                        <li><a href="#">Contact Us</a></li>
                    </ul>
                </div>
                <div className="col-md-4 col-6">
                    <p className="h6 pb-1">FAQ</p>
                    <ul className="d-inline-block">
                        <li className=""><a href="#">Your Questions Answered</a></li>
                        <li><img className="img" src={flag} alt="flag"/>  Made in the USA</li>
                        
                    </ul>
                </div>
                
                
                
                <div className="col-md-4 col-6">
                    <p className="h6 pb-1">MEDIA</p>
                    <ul className="d-inline-block">
                        <li><a href="#">Press</a></li>
                        {/* <li><a href="#">Subscribe to our press releases</a></li> */}
                        <li><a href="#">Pictures &amp; Videos</a></li>
                    </ul>
                </div>
                <div className="col-md-4 col-6">
                   
                    <p className="h6 pb-1">PARTNERSHIP &amp; <br/> COLLABORATIONS</p>
                    <ul className="d-inline-block">
                        <li><a href="#">Business Development</a></li>
                        
                    </ul>
                    
                </div>

               
                
            </div>
                        

            <div className="row  text-center justify-content-center mx-md-5 px-md-5 pb-2">
                <p className="text-center p1 pb-3">We will donate $10,000 of our sales to BarronParkDonkeys.org</p>
            </div>
            <div className="row pt-1 justify-content-center mx-md-5 px-md-5">
                <div className="col-md-3 col-12 text-center pb-2">
                    {/* <a className="px-2" href="https://www.youtube.com/"><i className="fa fa-youtube-play social-icon pr-lg-0"></i></a> */}
                    <a  className="px-2" href="https://www.instagram.com/"><i className="fa fa-instagram social-icon pr-lg-0 "></i></a>
                    <a className="px-2" href="https://discord.gg/"><i className="fa fa-discord-alt social-icon pr-lg-0 "></i></a>
                    <a className="px-2" href="https://twitter.com/"><i className="fa fa-twitter social-icon pr-lg-0 "></i></a>
                </div>
                <div className="col-md-5 col-12 ">
                    <p className="text-white-50 text-center"> ©2022 MetaParallel, Inc. All rights reserved.</p>
                </div>
                <div className="col-md-4 col-12">
                    <p id="terms" className="text-center">
                        <a className="" href="#/terms">Terms &amp; Conditions</a>
                    </p>
                </div>
            </div>
            {/* <div className="row mx-5 justify-content-center p-0 m-0">
                <div className="col-md-4">
                    <p className="text-white-50">All Rights Reserved.</p>
                </div>
            </div> */}
        </div>
    </footer>
  );
}
// export default Footer;