import React from 'react';
import Main from './components/Main';
import { useEffect } from "react";
import WebFont from 'webfontloader';



// class App extends React.Component {
//   render() {
//     return(
//       <div className="Donkey">
//         <Main/>
//       </div>
//     );
    
//   }
// }

// export default App;

export const App = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Ubuntu', 'Krona One']
      }
    });
   }, []);

  return (
    <div className="Donkey">
      <Main/>
    </div>
  );
}
