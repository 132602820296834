import React from 'react';


import donkey from '../assets/pictures/auto_25.png';

import pad from '../assets/pictures/PAD_copyright.png';
import pad1 from '../assets/pictures/PAD_ve.png';
import {Button} from 'reactstrap';

export const About = () => {

    return (
        <>
            <div className="container-fluid about-container">
                <div className="row pt-3 pb-5">      
                    <div className="col-12"> 
                        <div className="bg ">
                            <div className="circles">
                                <div id="circle1"></div>
                                <div id="circle2"></div>
                                <div id="circle3"></div>
                                <div id="circle4"></div>
                                <div id="circle5"></div>
                                <div id="circle7"></div>
                            </div>
                            <div className="glass1">
                            </div>
                        </div>
                        <div className="circle8">
                        </div>
                    </div>
                    
                </div>

                <div className="row ">
                    <div className="col-12 ">
                        <img className="img-fluid mx-auto pad d-none d-md-block" src={pad} alt="pad_h"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">          
                        <img className="img-fluid pad1 d-block d-md-none" src={pad1} alt="pad_v"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <img className="img dony" src={donkey} alt="don"/>  
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="main-heading lh-1 d-none d-lg-block shining">
                            <br/> Hold to Earn. <br/> Grow with community
                        </div>
                        <div className="main-heading1 lh-1 d-none d-md-block d-lg-none shining">
                            <br/> Hold to Earn. <br/> Grow with community
                        </div>
                        <div className="p-before-text lh-1  d-none d-md-block my-auto">
                                Born in <br/>Palo Alto, CA
                        </div>
                        <div className="p-before-text3 lh-1  d-block d-md-none my-auto">
                                Born in <br/>Palo Alto, CA
                        </div>
                        <div className="p-before-text2 lh-1  d-block d-md-none my-auto shining">
                            <br/> Hold to Earn. <br/> Grow with community
                        </div>
                       
                    </div>
                </div>
                
               
                
                <div className="row gx-5 align-items-center">
                    <div className="col-md-8">

                        <div>    </div>

                    </div>
                    <div className="col-md-4 justify-content-center">
                        <div className="mb-5 mb-lg-0 text-center text-lg-start">
                            {/* <div className="display-3 lh-1 mb-3 header-text">
                            Hold to earn.<br/>Grow with community<br/>
                            
                            </div> */}
                            
                            {/* <div className="p-before-text lh-1 h5">
                                Born in
                            </div>
                            <div className="p-text lh-1 h5">
                                Palo Alto, CA
                            </div> */}
                            {/* <div className="p-after-text lh-1 h6">
                                Your local community embracing reality and imagination. <br/>Unique NFT recreation of the donkey generations that have been lived in. 
                            </div> */}
                            {/* <button className="button-a button-a-position">
                                <span className="text">Button</span>
                            </button> */}
                            {/* <button className="button-c">
                                <span className="text">Start Now</span>
                                <span className="blob"></span>
                                <span className="blob"></span>
                                <span className="blob"></span>
                                <span className="blob"></span>    
                            </button>  */}
                        </div>
                        {/* <div className="header-text">
                            Hold to earn<br/>
                            Grow with community<br/>
                            
                        </div>
                        <div className="p-text">
                            Palo Alto, CA
                        </div>
                        <div className="p-before-text">
                            Born in
                        </div>
                        <div className="p-after-text">
                            Your local community embracing reality and imagination. Unique NFT recreation of the donkey generations that have been lived in. 
                        </div>
                        <Button className="header-button">
                            Start now
                        </Button> */}
                    
                    
                    </div> 
                </div>
            </div>
                
                

            
            
            
            
            
        </>
        
    );
}


