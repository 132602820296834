import React from 'react';
import roadmap from '../assets/pictures/roadmap.png';
import roadmap1 from '../assets/pictures/roadmap1.png';


export const RoadmapLine = () => {

    return (
        <>
            <div className="container-fluid line-container bg3"> 
                <div className="row-fluid my-5">
                    <div className="col-5 wh_5 offset-1 pt-5">
                        Meta Parallel Construction Plan
                    </div>
                    
                </div>
                {/* <div className="row-fluid  pt-4">
                        <div class="label one overflow-visible"></div>
                        <div class="progress-bar overflow-visible">
                        <div class="progress fill-1  d-block float-end">
                            <div class="circle1"><div class="circle"></div></div>
                            <div class="glow"></div>
                        </div>
                        </div>
                    


                </div> */}
                <div className="row  pt-5 pb-5">
                    <img className="img-fluid d-none d-sm-block" src={roadmap} alt="line"/>
                </div>
                <div className="row pb-5">
                    <img className="img-fluid d-block d-sm-none" src={roadmap1} alt="line"/>
                </div>
            </div>


            
        </>
        
    );
}
