import React from 'react';
import { useState, useEffect, useRef, useCallback } from "react";
import { Navbar, NavbarBrand, Nav, NavbarToggler, Collapse, NavItem, NavLink,} from 'reactstrap';
import { Divide as Hamburger } from 'hamburger-react';
import {Logo} from './Logo';
// import imgsrc from '../assets/pictures/Group.png';
import { useInView } from "react-intersection-observer";
import '../assets/css/style.scss';



const divStyle = {
  background: 'linear-gradient(280.61deg, #FFDDC5 8.29%, rgba(151, 136, 216, 0) 90.54%)',

}


export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
    
  const toggle = () => setIsOpen(!isOpen);

  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    // console.log(window.scrollY)
    if(window.scrollY > 70){
      setNavbar(true);
    }else{
      setNavbar(false);
    }
  }

  window.addEventListener('scroll', changeBackground);

  // {navbar? 'navbar-active':'navbar'}

      return (
        <>
          <header className="">
            {/* <Navbar light expand="md" className={navbar? 'navbar-active fixed-top nav-bg-blur':'fixed-top nav-bg-blur'} > */}
            <Navbar light expand="md" className= "navbar-active fixed-top nav-bg-blur" >
                {/* <NavbarBrand className={navbar? 'logo-active':''}><Logo/></NavbarBrand> */}
                <NavbarBrand className='logo-active'><Logo className="ml-5"/></NavbarBrand>
                {/* <svg width="129" height="59" viewBox="0 0 129 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M50.5122 44.4888C50.909 44.2606 51.1536 43.8378 51.1536 43.3801L51.1536 15.4465C51.1536 14.9888 50.909 14.566 50.5122 14.3379L26.2142 0.366541C25.8195 0.139601 25.334 0.1396 24.9393 0.366541L0.641342 14.3379C0.244563 14.566 -3.49875e-05 14.9888 -3.50075e-05 15.4465L-3.62285e-05 43.3801C-3.62485e-05 43.8378 0.244562 44.2606 0.64134 44.4888L24.9393 58.4601C25.334 58.687 25.8195 58.687 26.2142 58.4601L50.5122 44.4888Z" fill="black"/>
                        <path d="M25.9393 23.0868C25.9563 23.1828 25.9676 23.2845 25.9789 23.3805C25.9845 23.4765 25.9902 23.5839 25.9902 23.6968V23.7194V23.742C25.9902 23.855 25.9845 23.9623 25.9789 24.0583C25.9732 24.1544 25.9563 24.256 25.9393 24.3521V24.3747L25.8659 24.9621C25.8489 24.9791 25.8433 25.013 25.8433 25.0581C25.8264 25.1372 25.8094 25.2107 25.7925 25.2784C25.7755 25.3462 25.7529 25.4197 25.719 25.4987C25.4931 26.25 25.1542 26.9165 24.7079 27.4983C24.2617 28.0858 23.7194 28.5829 23.0868 28.9896C22.5502 29.3172 21.9571 29.5714 21.3075 29.7578C20.6579 29.9442 19.9631 30.0402 19.2345 30.0402H16.0374V33.0791C16.0374 34.327 15.0258 35.3385 13.7779 35.3385H13.4277V17.3987H19.2345C20.6974 17.3987 21.9853 17.7489 23.0924 18.4493C23.7251 18.856 24.2673 19.3531 24.7136 19.9405C25.1598 20.528 25.4987 21.1832 25.7247 21.9175C25.7925 22.0983 25.832 22.2508 25.8489 22.3807C25.8489 22.4316 25.8546 22.4598 25.8715 22.4767C25.8885 22.5728 25.8998 22.6744 25.9054 22.7705C25.9111 22.8665 25.928 22.9569 25.9393 23.0416V23.0868ZM19.5451 27.7469C20.0818 27.7469 20.5732 27.6339 21.0194 27.408C21.4657 27.182 21.8554 26.877 22.1887 26.5042C22.5219 26.1314 22.7818 25.7021 22.9682 25.222C23.1546 24.7418 23.2506 24.2448 23.2506 23.7194C23.2506 23.1998 23.1546 22.697 22.9682 22.2169C22.7818 21.7368 22.5219 21.3075 22.1887 20.9347C21.8554 20.5619 21.4657 20.2625 21.0194 20.0309C20.5732 19.805 20.0818 19.692 19.5451 19.692H16.0543V27.7469H19.5451Z" fill="white"/>
                        <path d="M15.3425 44.0543L22.6405 26.1653L29.9384 44.0543H28.7939C27.8815 44.0543 27.0643 43.4898 26.7413 42.6365H18.5622C18.2248 43.4921 17.3986 44.0543 16.4789 44.0543H15.3425ZM19.4434 40.3432H25.8376L22.6405 32.1415L19.4434 40.3432Z" fill="white"/>
                        <rect x="28.9612" y="17.9861" width="2.25943" height="7.34314" rx="1.12971" fill="white"/>
                        <rect x="32.3503" y="17.9861" width="2.25943" height="7.34314" rx="1.12971" fill="white"/>
                        <path d="M40.2583 34.6495C40.2583 37.6131 38.8462 38.321 36.8692 38.8859C34.8922 39.4508 32.0679 37.6131 32.0679 34.6495C32.0679 31.6858 34.4704 28.436 37.434 28.436C40.3976 28.436 40.2583 31.6858 40.2583 34.6495Z" fill="#787878"/>
                        <circle cx="36.8692" cy="31.5428" r="1.12971" fill="white"/>
                        <circle cx="31.2205" cy="28.1536" r="1.12971" fill="white"/>
                        <path d="M32.9605 23.1829C34.1636 23.1829 35.2933 23.4201 36.3383 23.8889C37.3889 24.3634 38.304 25.0017 39.0835 25.8151C39.863 26.6285 40.4844 27.5774 40.9362 28.6563C41.3938 29.7409 41.6197 30.8988 41.6197 32.1358C41.6197 33.3729 41.3994 34.5308 40.9588 35.6154C40.5182 36.6999 39.9138 37.6432 39.14 38.4453C38.3661 39.2474 37.4511 39.8913 36.3948 40.3602C35.3385 40.8347 34.1918 41.0662 32.9548 41.0662H26.7131V25.4423C26.7131 24.1944 27.7247 23.1829 28.9726 23.1829H32.9605ZM32.6667 38.7729C33.5762 38.7729 34.4178 38.5978 35.1803 38.2476C35.9429 37.8974 36.6038 37.4229 37.1573 36.8185C37.7109 36.2141 38.1402 35.508 38.4509 34.7059C38.7615 33.8982 38.9141 33.0396 38.9141 32.1246C38.9141 31.2095 38.7615 30.3509 38.4509 29.5432C38.1402 28.7354 37.7053 28.0293 37.146 27.4306C36.5868 26.8262 35.9203 26.3517 35.1578 26.0015C34.3952 25.6513 33.5649 25.4762 32.6667 25.4762H29.3228V38.7729H32.6667Z" fill="white"/>
                  </svg> */}
                  {/* <Hamburger color={navbar? 'white':'black'} toggled={isOpen} toggle={toggle}  /> */}
                  <Hamburger color={'white'} toggled={isOpen} toggle={toggle}  />
                    <Collapse  className="justify-content-end"  isOpen={isOpen} navbar >
                        <Nav className="pr-4 mx-4" navbar>
                            {/* <NavLink className={navbar? 'nav-link-active mx-1':'mx-1'} href="#">Community</NavLink>
                            <NavLink className={navbar? 'nav-link-active mx-1':'mx-1'} href="#">RoadMap</NavLink>
                            <NavLink className={navbar? 'nav-link-active mx-1':'mx-1'} href="#">Team</NavLink>
                            <NavLink className={navbar? 'nav-link-active mx-1':'mx-1'} href="#">Airdrop</NavLink>
                            <NavLink className={navbar? 'nav-link-active mx-1':'mx-1'} href="#">FAQ</NavLink> */}
                            <NavLink className={'nav-link-active mx-1'} href="#">Community</NavLink>
                            <NavLink className={'nav-link-active mx-1'} href="#">RoadMap</NavLink>
                            <NavLink className={ 'nav-link-active mx-1'} href="#">Team</NavLink>
                            <NavLink className={'nav-link-active mx-1'} href="#">Airdrop</NavLink>
                            <NavLink className={'nav-link-active mx-1'} href="#">FAQ</NavLink>
                        </Nav>
                        <Nav className="pr-4" id="pl-3" navbar>
                            {/* <NavLink className={navbar? 'nav-link-active btn btn-social-icon btn-instagram':'btn btn-social-icon btn-instagram'} href="http://instagram.com/"><i className="fa fa-instagram pr-lg-0 d-none d-md-block " aria-hidden="true"></i></NavLink>
                            <NavLink className={navbar? 'nav-link-active btn btn btn-social-icon btn-discord':'btn btn-social-icon btn-discord'} href="http://discord.com/"><i className="fa fa-discord-alt pr-lg-0 d-none d-md-block " aria-hidden="true"></i></NavLink>
                            <NavLink className={navbar? 'nav-link-active btn btn-social-icon btn-twitter':'btn btn-social-icon btn-twitter'} href="http://twitter.com/"><i className="fa fa-twitter pr-lg-0 d-none d-md-block "  aria-hidden="true"></i></NavLink> */}
                            <NavLink className={'nav-link-active btn btn-social-icon btn-instagram'} href="http://instagram.com/"><i className="fa fa-instagram pr-lg-0 d-none d-md-block " aria-hidden="true"></i></NavLink>
                            <NavLink className={'nav-link-active btn btn btn-social-icon btn-discord'} href="http://discord.com/"><i className="fa fa-discord-alt pr-lg-0 d-none d-md-block " aria-hidden="true"></i></NavLink>
                            <NavLink className={'nav-link-active btn btn-social-icon btn-twitter'} href="http://twitter.com/"><i className="fa fa-twitter pr-lg-0 d-none d-md-block "  aria-hidden="true"></i></NavLink>
                        </Nav>    
                    </Collapse>    
            </Navbar>
          </header>
          
        </>
      );
 }


