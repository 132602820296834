import React from 'react';
import { Button, Collapse,ListGroupItem } from 'reactstrap';

class GroupCollapse extends React.Component {
  constructor(props) {
    super(props);
    
    this.toggle = this.toggle.bind(this);
    this.state = { 
        
        
     };
  }
  
  toggle() {
    this.props.toggle(this.props.phase);
    // this.setState({ 
    //     collapse: !this.state.collapse
        
    //  });
  }
  
  render() {
    const phase = this.props.phase;
    return (
        <div className="row justify-content-md-end">
          <Button className="pb-4 col-md-8 button-text  border-bottom" onClick={this.toggle}>
            <span className="pl-0 pt-2 mt-2 roadmap-phase">{phase.title}</span>
            <i className={this.props.isOpen? "fa fa-minus-circle float-right1 pt-2" :"fa fa-plus-circle float-right1 pt-2" } aria-hidden="true"></i>
          </Button>
          <Collapse className="m-4 p-4" isOpen={this.props.isOpen}>{phase.description}</Collapse>
        </div>
    );
  }
}

export default GroupCollapse;