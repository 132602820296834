import React from 'react';

import stanford from '../assets/pictures/20220609-100308.png';
import b from '../assets/pictures/b.png';
import lookout from '../assets/pictures/looking_outside_v6_2.jpg';
import {Row, Col} from 'reactstrap';

export const Why = () => {

    return (
        <>
            <div className="container-fluid why-container">
                <div className="row justify-content-center pt-5">
                    <Col  className="h1 wh_3 offset-3" md={4} xs={12}>Why PAD NFT?</Col>
                        <div className="xsWhy">
                            <div className="descFloat p12 col-md-4 offset-3 py-3">PAD holders can earn monetary rewards on every activities in MetaParallel.</div>
                                    <div className="xsWhyDescBox">
                                    <div className="descFloat p12 col-md-4 offset-3 py-3 ">*Each PAD NFT grants early access to MetaParallel, voting rights and future real estate airdrops.</div>
                                </div>
                        <img className="xsWhyImg" src={b} alt="..." />
                    </div>

                    <Row className="whyCol pt-5">
                        <Col className="imgFCol" xs={12} md={6}>
                            <img className="imgF" src={b} alt="..." />
                        </Col>
                        <Col xs={12} md={6}>
                            <div style={{
                                height: 50}}>
                            </div>
                            <div className="descFloat p12 col-md-4 offset-1 py-3">PAD holders can earn monetary rewards on every activities in MetaParallel.</div>
                            <div className="descFloat p12 col-md-4 offset-1 py-3 ">Each PAD NFT grants early access to MetaParallel, voting rights and future real estate airdrops.</div>
                        </Col>
                    </Row>
                       
                        
                          
                        
                        
                        
                    </div>
                    
               
               
                
                       
                 
            </div>
            
        </>
        
    );
}
