import React from 'react';
import GroupCollapse from './ListGroupCollapse';


const roadmap ={
    phase1:{
        title: "Phase 1: Palo Alto Donkey",
        description : "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem",
    },
    phase2:{
        title: "Phase 2: Meta Parallel Community Construction ",
        description : "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem",
    },
    phase3:{
        title: "Phase 3: Meta Parallel Real Estate",
        description : "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem",     
    }
}

class Phase extends React.Component {
  constructor(props) {
    super(props);
    
    this.toggle = this.toggle.bind(this);
    this.state = {
    };
  }
  
  toggle(thing) {
    this.setState({ collapse: this.state.collapse === thing ? null : thing });
  }
  render() {
    return (
        <div className="container-fluid">
            <div className="row pt-0 justify-content-center">
                {Object.keys(roadmap).map((key, index) =>
                <GroupCollapse key={index}
                    phase={roadmap[key]}
                    isOpen={this.state.collapse === roadmap[key]}
                    toggle={this.toggle} />
                )}
            </div>
        </div>
    );
  }
}
export default Phase;