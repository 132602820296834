// import React from 'react';
// import  ReactDOM  from 'react-dom';
// import {App} from './App';
// import { createRoot } from 'react-dom/client'; //react18


// ReactDOM.render(
//     <React.StrictMode>
//       <App/>
//     </React.StrictMode>,
//     document.getElementById('root')
// );


//react 18
import React from "react";
import ReactDOM from "react-dom/client";

// import "./index.css";
import "./assets/css/style.css";
// import "./assets/css/style.sass";
import {App} from "./App";
import reportWebVitals from "./reportWebVitals";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();




