import React from 'react';

import house from '../assets/pictures/header_v5.png';
import acce from '../assets/pictures/acc_0148.png';
import {Row, Col} from "reactstrap";



export const What = () => {

    return (
        <>
             <div className="container-fluid what-container">
                <div className="row pt-5 justify-content-end padd">
                    
                </div>
                
                <div className="row justify-content-center align-items-center px-auto">
                    <Col className="offset-4">
                        <div className="h1 text-start wh_1">What is PAD NFT?</div>
                           
                    </Col>
                    
                </div>
                <div className="row justify-content-center  pb-5 ">
                    <Col className="offset-4 pt-3">
                        <div className="p11 d-block">PAD stands for "Palo Alto Donkey". It's an unique NFT collection of the donkey generations that have been lived in Palo Alto since the 1920s.  You can use it to unlock the limitless life in our reality-based metaverse -- MetaParallel. PAD is launching on Ethereum <i className="fa fa-ethereum" aria-hidden="true"></i></div>
                    </Col>
                    
                </div>
                <div className="row pt-3 pb-4 mb-4">
                    <div className="col-6 pt-5 offset-md-1 d-none d-md-block">
                        <div className="h1 padd wh_22">Kickstart the 1st <strong className="text1">location-based</strong> metaverse.</div>
                    </div>
                    <div className="col d-sm-block d-md-none">
                        <div className="h1 padd wh_2 ">Kickstart the 1st <strong className="text1">location-based</strong> metaverse.</div>
                    </div>
                    
                </div>
                <div className="row pb-5 mb-5 pl-3 position-relative padd d-sm-block d-md-none">
                    <div className="col-6 pb-5 mb-2">
                        <img className="house" src={house} alt="house"/>
                    </div>
                    <div className="col-12 pb-2 mb-5">
                    </div>
                </div>
                <div className="row pb-5 mb-5 pl-3 position-relative padd  d-none d-md-block d-lg-none">
                    <div className="col-6 pb-5 mb-2">
                        <img className="house1" src={house} alt="house"/>
                    </div>
                    <div className="col-12 pt-5">
                    </div>
                </div>
                <div className="row pt-5 pb-5 mb-5 pl-3 position-relative padd d-none d-lg-block d-xl-none">
                    <div className="col-6 pb-5 mb-2">
                        <img className="house3" src={house} alt="house"/>
                    </div>
                </div>
                <div className="row pt-5 pb-5 mb-5 pl-3 position-relative padd d-none d-xl-block">
                    <div className="col-6 pb-5 mb-2 ">
                        <img className="house4" src={house} alt="house"/>
                    </div>
                </div>
                <div className="row ">
                    <div className="spinner"><div className="inner"><span className="text-start">&nbsp;MetaParallel-Mirroring Reality</span> </div></div>
                </div>
            </div>
            
        </>
        
    );
}
