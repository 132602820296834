import React from 'react';
// import img1 from '../assets/pictures/logo2.png';
import img1 from '../assets/pictures/logo8.webp';
import img2 from '../assets/pictures/logo7.png';
import img3 from '../assets/pictures/logo10.webp';


export const Logo = () => {
    return (
        <>
            <div className="main-logo-header is-visible pivot pl-4">
                <div href="#" className="main-logo">
                    <div className="pivot">
                        <div className="part-1">
                            <div className="texture">
                                <img className="h-50" src={img1}  alt="logo"/>
                            </div>
                            <div className="part-2">
                                <div className="texture">
                                    <img src={img3} className="h-50" alt="logo"/>
                                </div>
                                <div className="part-3">
                                    <div className="texture">
                                        <img src={img3} className="h-50" alt="logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="back part-1">
                            <div className="texture">
                                <img src={img1} className="mh-100" alt="logo"/>
                            </div>
                            <div className="back part-2">
                                <div className="texture">
                                    <img src={img1} className="mh-100" alt="logo"/>
                                </div>
                                <div className="back part-3">
                                    <div className="texture">
                                        <img src={img1} className="mh-100" alt="logo"/>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>   
                </div>
            </div>
        </>
    );
}

//font version
// export const Logo = () => {
//     return (
//         <>
//             <div className="main-logo-header is-visible pivot">
//                 <a href="#" className="main-logo">
//                     <div className="pivot">
//                         <div className="part-1">
//                             <div className="texture">
                             
//                                 <p>Palo Alto Donkey</p>
//                             </div>
//                             <div className="part-2">
//                                 <div className="texture">
                                    
//                                     <span>PaloAltoDonkey</span>
//                                 </div>
//                                 <div className="part-3">
//                                     <div className="texture">
//                                         <span>Palo Alto Donkey</span>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="back part-1">
//                             <div className="texture">
//                                 <span>Palo Alto Donkey</span>
//                             </div>
//                             <div className="back part-2">
//                                 <div className="texture">
//                                     <span>Palo Alto Donkey</span>
//                                 </div>
//                                 <div className="back part-3">
//                                     <div className="texture">
//                                         <span>Palo Alto Donkey</span>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>   
//                 </a>
//             </div>
//         </>
//     );
// }