import React, { Component } from 'react';
import {Header} from './Header';
import HeroSection from './HeroSection';
import { About } from './About';
import { Contact } from './Contact';
import { Footer } from './Footer';
import { Offers} from './Offers';
import { Team} from './Team';
import RoadMap from './Roadmap';
import Dao from './Dao';
import Galaxy from './Galaxy';
import {FAQ} from './Faq';
import {Logo} from './Logo';
import {Intro} from './Intro';
import {Coming} from './ComingSoon';
import {Three} from './Three';

import { About1 } from './About1';

import { About2 } from './About2';

import { What } from './What';
import { Why } from './Why';
import { RoadmapLine } from './Roadmap_line';
import {Gallery} from './Gallery';


function Main() {
    return (
        <>
            <Header/>
            <About/>
            {/* <About2/> */}
            <What/>
            <Why/>
            <Gallery/>
            <RoadmapLine/>
            <Team/>
            {/* <Three/> */}
            {/* <Intro/> */}
            {/* <Coming/> */}
        
            {/* <Coming/> */}
            {/* <FAQ/> */}
            {/* <Logo/> */}
            {/* <Offers/> */}
            {/* <RoadMap/>
            <Dao/>
            <Galaxy/>
            <Team/> */}
            {/* <HeroSection/> */}
            {/* <Contact/> */}
            <Footer/>
        </>
    );
}


export default Main;