import React from 'react';
// import imgsrc from '../assets/pictures/0021@1x.png';

import { Collapse, Accordion, AccordionItem, AccordionHeader, Card, CardBody, CardText, Button } from 'reactstrap';
import Phase from './Phase';




export default class RoadMap extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { 
            // collapse: true
            
         };
      }
    
      toggle() {
        this.setState({ 
            collapse: !this.state.collapse
            
         });
      }

    render(){
        // console.log(this.state.collapse)
        return (
            <>
                <div className="container-fluid road-container ">
                    <div className="row ">
                        <div className="col-10 pl-1 pt-3">
                            <h2 className="text-start">RoadMap</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-4 pb-5 pl-5 col-6">
                            {/* <img className="img-fluid1 img-fluid pt-5" src={imgsrc} alt="road_map"/>  */}
                        </div>
                        <div className="col-md-6 pt-0 mb-4 pr-4 col-6">
                            <Phase/>       
                        </div>
                    </div>
                    </div>     
                </div>
            </>
        );
    }
}



{/* <div className="col-md-9">
                    <h3>Pic of MP</h3> 
                </div>      
            </div>
            <div className="row">
                <h2 className="text-center">DAO</h2>
            </div> */}
            





{/* <Accordion open="1" onClick={this.toggle}>
                            <AccordionItem>
                                <AccordionHeader targetId="1">
                                    Phase 1: Palo Alto Donkey
                                </AccordionHeader>
                                <AccordionItem accordionId="1">
                                    This is the first item's accordion body.    
                                </AccordionItem>
                            </AccordionItem>
                            {/* <AccordionItem>
                                <AccordionHeader targetId="2">
                                    Phase 1: Palo Alto Donkey
                                </AccordionHeader>
                                <AccordionItem accordionId="2">
                                    This is the first item's accordion body.    
                                </AccordionItem>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="3">
                                    Phase 1: Palo Alto Donkey
                                </AccordionHeader>
                                <AccordionItem accordionId="3">
                                    This is the first item's accordion body.    
                                </AccordionItem>
                            </AccordionItem> */}
                        // </Accordion> */}






{/* <div className="row justify-content-md-end">
                            <Button className="pb-4 col-7 button-text d-md-block border-bottom" onClick={this.toggle} >
                                    <span className="p-0 roadmap-phase">Phase 1: Palo Alto Donkey</span>
                                    <i className={this.state.collapse? "fa fa-minus-circle float-right1 pt-2" :"fa fa-plus-circle float-right1 pt-2" } aria-hidden="true"></i>
                                    
                                </Button>
                                    
                                    <Collapse className="p-0" isOpen={this.state.collapse}>
                                        <Card className="p-0">
                                            <CardBody className="p-0">
                                            Anim pariatur cliche reprehenderit,
                                            enim eiusmod high life accusamus terry richardson ad squid. Nihil
                                            anim keffiyeh helvetica, craft beer labore wes anderson cred
                                            nesciunt sapiente ea proident.
                                            </CardBody>
                                        </Card>
                                    
                                    </Collapse>
                        </div>
                        <div className="row justify-content-md-end">
                        <Button className="pb-4 col-7 button-text d-md-block border-bottom" onClick={this.toggle} >
                                <span className="p-0 roadmap-phase">Phase 1: Palo Alto Donkey</span>
                                <i className={this.state.collapse? "fa fa-minus-circle float-right1 pt-2" :"fa fa-plus-circle float-right1 pt-2" } aria-hidden="true"></i>
                                
                            </Button>
                                
                                <Collapse className="p-0" isOpen={this.state.collapse}>
                                    <Card className="p-0">
                                        <CardBody className="p-0">
                                        Anim pariatur cliche reprehenderit,
                                        enim eiusmod high life accusamus terry richardson ad squid. Nihil
                                        anim keffiyeh helvetica, craft beer labore wes anderson cred
                                        nesciunt sapiente ea proident.
                                        </CardBody>
                                    </Card>
                                
                                </Collapse>
                        </div> */}
