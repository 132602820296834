import React from 'react';
import img1 from '../assets/pictures/auto_25_m.png';
import img2 from '../assets/pictures/auto_46.png';
import { Button, Form, Label, Input, FormText, Link, Row, Col } from 'reactstrap';

export function Gallery() {

    const padList = [{
        title: 'PAD',
        desc: 'NO.01',
        img: img1
    }, {
        title: 'PAD',
        desc: 'NO.02',
        img: img2
    }, {
        title: 'PAD',
        desc: 'NO.03',
        img: img1
    }, {
        title: 'PAD',
        desc: 'NO.04',
        img: img2
    }, {
        title: 'PAD',
        desc: 'NO.05',
        img: img1
    }, {
        title: 'PAD',
        desc: 'NO.06',
        img: img2
    }];

    return (
        <div className="section-box pt-5">
            <Row className="justify-content-center " style={{
                marginLeft: 0,
                marginRight: 0
            } }>
                <Col className="wh_7" md={4} xs={12} >Metaverse<br/> For Reality</Col>
            </Row>
            <Row className="pt-5 mt-2 my-md-5 py-md-5" style={{
                marginLeft: 0,
                marginRight: 0

            } }>
                {padList.map((v, i) => {
                    return (
                        <Col className='sec-col' xs={4} md={2}>
                            <div className='section-item-box justify-content-center'>
                                <div class="secondAnimation">
                                    <img className='section-item-img' src={v.img}  alt='...'/>
                                    <div className='section-item-tit'>
                                        {v.title}
                                    </div>
                                    <div className='section-item-label'>
                                        {v.desc}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
}